const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const PromoCodeAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/add.vue');
const PromoCodeDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/detail.vue');
const PromoCodeList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/index.vue');
const PromoItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/add.vue');
const PromoItemClone = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/add.vue');
const PromoItemDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/detail.vue');
const PromoItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/edit.vue');
const PromoItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/index.vue');
const PromoItemTranslate = () => import(/* webpackChunkName:"exoty" */ '@/modules/promotion/item/view/translate.vue');
const PromoPrototypeAdd = () => import(/* webpackChunkName:"exoty" */ '@/modules/promotion/prototype/view/add.vue');
const PromoPrototypeList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/prototype/view/index.vue');
const PromoPrototypeDetail = () => import(/*webpackChunkName:"exoty"*/ '@/modules/promotion/prototype/view/detail.vue');

export default [
    {
        path: '/app/promotion/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'item/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionItemList',
                        component: PromoItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionItemAdd',
                        component: PromoItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'PromotionItemEdit',
                        component: PromoItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'PromotionItemClone',
                        component: PromoItemClone,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionItemDetail',
                        component: PromoItemDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'PromotionItemTranslate',
                        component: PromoItemTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'code/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionCodeList',
                        component: PromoCodeList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionCodeAdd',
                        component: PromoCodeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionCodeDetail',
                        component: PromoCodeDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'prototype/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionPrototypeList',
                        component: PromoPrototypeList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionPrototypeAdd',
                        component: PromoPrototypeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'PromotionPrototypeClone',
                        component: PromoPrototypeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionPrototypeDetail',
                        component: PromoPrototypeDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];
