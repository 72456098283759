const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const QuestBoostAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/boost/view/add.vue');
const QuestBoostEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/boost/view/edit.vue');
const QuestBoostList = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/boost/view/index.vue');
const QuestBoostTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/boost/view/translate.vue');
const QuestItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/item/view/add.vue');
const QuestItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/item/view/edit.vue');
const QuestItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/item/view/index.vue');
const QuestItemTime = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/item/view/time.vue');
const QuestItemTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/item/view/translate.vue');
const QuestTypeAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/type/view/add.vue');
const QuestTypeDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/type/view/detail.vue');
const QuestTypeList = () => import(/* webpackChunkName: "exoty" */ '@/modules/quest/type/view/index.vue');

export default [
    {
        path: '/app/quest/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'boost/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'QuestBoostList',
                        component: QuestBoostList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'QuestBoostAdd',
                        component: QuestBoostAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':boostId/edit',
                        name: 'QuestBoostEdit',
                        component: QuestBoostEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':boostId/translate',
                        name: 'QuestBoostTranslate',
                        component: QuestBoostTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'item/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'QuestItemList',
                        component: QuestItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'QuestItemAdd',
                        component: QuestItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':itemId/edit',
                        name: 'QuestItemEdit',
                        component: QuestItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':itemId/time',
                        name: 'QuestItemTime',
                        component: QuestItemTime,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':itemId/translate',
                        name: 'QuestItemTranslate',
                        component: QuestItemTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'type/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'QuestTypeList',
                        component: QuestTypeList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'QuestTypeAdd',
                        component: QuestTypeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':typeId/detail',
                        name: 'QuestTypeDetail',
                        component: QuestTypeDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];
