import { App, inject, Plugin } from 'vue';
import AppModal from '@/plugins/modal/v-modal.vue';
import { useEventBus } from '@/plugins/event-bus';

export default {
    install: (app: App) => {
        app.component('AppModal', AppModal);

        const eventBus = useEventBus();

        const actions: VModalAction = {
            open(params: any) {
                eventBus.emit('open', params);
            },
            close(params: any) {
                eventBus.emit('close', params);
            }
        };

        /* It is common to use both `app.config.globalProperties` and `provide` in the same plugin */
        app.config.globalProperties.$vModal = actions;
        app.provide('vModal', actions);
    }
} as Plugin;

export function useVModal() {
    const vModal = inject<VModalAction>('vModal');
    if (!vModal) {
        throw new Error('No vModal provided!');
    }

    return vModal;
}

type VModalAction = {
    open: (params: { name: string; payload?: any }) => void;
    close: (params: { name: string }) => void;
};
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $vModal: VModalAction;
    }
}
