import { defaultStorePagingProps } from '@/store/mixins';

const promotionPrototypeModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state
    },
    mutations: {
        ...defaultStorePagingProps.mutations
    },
    actions: {
        ...defaultStorePagingProps.actions
    },
    getters: {
        ...defaultStorePagingProps.getters
    }
};

export default promotionPrototypeModule;
