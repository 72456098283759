import { applicationService } from '@/services/applications';

export type App = {
    id: number;
    name: string;
};

const applicationModule = {
    namespaced: true,
    state: {
        apps: [] as Array<App>,
        appId: null as number
    },
    mutations: {
        setAppId: (state: any, appId: number): void => {
            if (state.apps && state.apps.length) {
                if (state.apps.find((app: App) => app.id === appId)) {
                    state.appId = appId;
                } else {
                    state.appId = state.apps[0].id;
                }
            }
        },
        setApps: (state: any, apps: Array<App>): void => {
            if (apps && apps.length) {
                state.apps = apps;
                if (!state.appId) {
                    state.appId = apps[0].id;
                }
            }
        }
    },
    actions: {
        changeAppId: (context: any, appId: number): void => {
            context.commit('setAppId', appId);
        },
        loadApps: async (context: any): Promise<void> => {
            const response = await applicationService.app.list();
            if (response.code === 200) {
                const apps: Array<App> = response.data;
                context.commit('setApps', apps);
            }
        }
    },
    getters: {
        appId: (state: any): number => state.appId,
        apps: (state: any): Array<App> => state.apps
    }
};

export default applicationModule;
