const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ScratchTicketAdd = () => import(/* webpackChunkName: "exoty" */ './ticket/view/add.vue');
const ScratchTicketEdit = () => import(/* webpackChunkName: "exoty" */ './ticket/view/edit.vue');
const ScratchTicketList = () => import(/* webpackChunkName: "exoty" */ './ticket/view/index.vue');

export default [
    {
        path: '/app/scratch/ticket',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ScratchTicketList',
                component: ScratchTicketList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ScratchTicketAdd',
                component: ScratchTicketAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'ScratchTicketEdit',
                component: ScratchTicketEdit,
                meta: { requiresAuth: true }
            }
        ]
    }
];
