const transactionModule = {
    namespaced: true,
    state: {
        page: 1,
        filter: { locale: null as any }
    },
    mutations: {
        setPage: (state: any, page: number): void => {
            state.page = page;
        },
        setFilter: (state: any, filter: any): void => {
            state.filter.locale = filter.locale ?? null;
        }
    },
    actions: {
        changePage: (context: any, page: number): void => {
            context.commit('setPage', page);
        },
        changeFilter: (context: any, filter: any): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        page: (state: any): number => state.page,
        filter: (state: any): any => state.filter
    }
};

export default transactionModule;
