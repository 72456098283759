import http from '@/utils/axios';

export const roleService = {
    listRole: async (paging: any, options: any) => {
        return http.doGet('/admin/role/account', {
            ...paging,
            ...options
        });
    },

    addRole: async (accountId: number, email: string, role: string) => {
        // post method
        return http.doPut('/admin/role/account', {
            accountId: accountId,
            email: email,
            role: role
        });
    },

    removeRole: async (accountId: number, role: string) => {
        return http.doDelete('admin/role/account/' + accountId + '/' + role);
    },

    listAppRole: (paging: any, options: any) => {
        return http.doGet('/admin/role/app/account', {
            ...options,
            ...paging
        });
    },

    addAppRole: async (accountId: number, email: string, role: string) => {
        // post method
        return http.doPut('/admin/role/app/account', {
            accountId: accountId,
            email: email,
            role: role
        });
    },

    removeAppRole: async (accountId: number, role: string) => {
        return http.doDelete('admin/role/app/account/' + accountId + '/' + role);
    },

    getRole: async () => {
        return http.doGet('admin/role');
    },

    getAppRole: async () => {
        return http.doGet('admin/role/app');
    }
};
