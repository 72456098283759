const Login = () => import(/* webpackChunkName: "exoty" */ './login.vue');

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { requiresUnauth: true }
    }
];
