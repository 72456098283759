const ForgotPassword = () => import(/* webpackChunkName: "exoty" */ './forgot-password.vue');

export default [
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { requiresUnauth: true }
    }
];
