import moment from 'moment';
import { SCREEN_SIZE_TYPE } from './themes';

export const calculateWindowSize = (windowWidth: number): SCREEN_SIZE_TYPE => {
    if (windowWidth >= 1200) {
        return SCREEN_SIZE_TYPE.LG;
    }
    if (windowWidth >= 992) {
        return SCREEN_SIZE_TYPE.MD;
    }
    if (windowWidth >= 768) {
        return SCREEN_SIZE_TYPE.SM;
    }
    return SCREEN_SIZE_TYPE.XS;
};

export const formatDate = (value: number, pattern: string = 'YYYY-MM-DD HH:mm:ss') => {
    if (value != undefined && value > 0) {
        return moment(value).format(pattern);
    }
    return null;
};

export const pageSize = 10;

export const timeUnits = [
    { value: 0, label: 'Minute' },
    { value: 1, label: 'Hour' },
    { value: 2, label: 'Day' },
    { value: 3, label: 'Week' },
    { value: 4, label: 'Month' }
];

export const renderTimeUnit = (value: any) =>
    `<span class='badge badge-success'> 
        ${timeUnits.find((item: any) => item.value == value).label}
    </span>`;

const colorList = [
    '#bdc3c7',
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#ecf0f1',
    '#f39c12',
    '#d35400',
    '#c0392b'
];

export function color(index: number) {
    return colorList[index % colorList.length];
}

export function generateID(): string {
    return Date.now().toString(16) + Math.random().toString(16);
}
