const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const Profile = () => import(/* webpackChunkName: "exoty" */ '@/modules/profile/view/edit.vue');

export default [
    {
        path: '/profile',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'Profile',
                component: Profile,
                meta: { requiresAuth: true }
            }
        ]
    }
];
