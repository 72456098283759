const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ApplicationAdd = () => import(/* webpackChunkName: "exoty" */ './view/add.vue');
const ApplicationEdit = () => import(/* webpackChunkName: "exoty" */ './view/edit.vue');
const ApplicationList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');

export default [
    {
        path: '/apps',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'ApplicationList',
                component: ApplicationList,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'ApplicationEdit',
                component: ApplicationEdit,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'ApplicationAdd',
                component: ApplicationAdd,
                meta: { requiresAuth: true }
            }
        ]
    }
];
