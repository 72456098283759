const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const VersionAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/version/view/add.vue');
const VersionEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/version/view/edit.vue');
const Version = () => import(/* webpackChunkName: "exoty" */ '@/modules/version/view/index.vue');

export default [
    {
        path: '/app/version',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'VersionList',
                component: Version,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'VersionAdd',
                component: VersionAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'VersionEdit',
                component: VersionEdit,
                meta: { requiresAuth: true }
            }
        ]
    }
];
