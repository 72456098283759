export const defaultStorePagingProps = {
    namespaced: true,
    state: {
        page: 1
    },
    mutations: {
        setPage: (state: any, page: number): void => {
            state.page = page;
        }
    },
    actions: {
        changePage: (context: any, page: number): void => {
            context.commit('setPage', page);
        }
    },
    getters: {
        page: (state: any): number => state.page
    }
};
