const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const TransactionTextEdit = () => import(/* webpackChunkName: "exoty" */ './text/view/edit.vue');
const TransactionTextExport = () => import(/* webpackChunkName: "exoty" */ './text/view/export.vue');
const TransactionTextList = () => import(/* webpackChunkName: "exoty" */ './text/view/index.vue');

export default [
    {
        path: '/app/transaction/text',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'export',
                name: 'TransactionTextExport',
                component: TransactionTextExport,
                meta: { requiresAuth: true }
            },
            {
                path: 'item',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'TransactionTextList',
                        component: TransactionTextList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':code/:locale',
                        name: 'TransactionTextEdit',
                        component: TransactionTextEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];
