const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const GiftLucky = () => import(/* webpackChunkName: "exoty" */ '@/modules/gift/view/lucky.vue');
const GiftSetting = () => import(/* webpackChunkName: "exoty" */ '@/modules/gift/view/setting.vue');

export default [
    {
        path: '/app/gift',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'lucky',
                name: 'GiftLucky',
                component: GiftLucky,
                meta: { requiresAuth: true }
            },
            {
                path: 'setting',
                name: 'GiftSetting',
                component: GiftSetting,
                meta: { requiresAuth: true }
            }
        ]
    }
];
