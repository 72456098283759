import { Role } from '@/interfaces/state';
import { IUser } from '@/interfaces/user';
import router from '@/router/index';
import { roleService } from '@/services/role';

function convertRole(roles: Array<string>) {
    const result = [] as Array<Role>;
    Object.values(Role).forEach(value => {
        if (roles.indexOf(value) >= 0) {
            result.push(value);
        }
    });

    return result;
}

export default {
    setToken: (context: any, token: string): void => {
        context.commit('setToken', token);
    },
    setUser: (context: any, payload: IUser): void => {
        context.commit('setUser', payload);
    },
    loadRoles: async (context: any): Promise<void> => {
        const response = await roleService.getRole();
        if (response.code === 200) {
            context.commit('setRoles', convertRole(response.data));
        }
    },
    loadAppRoles: async (context: any): Promise<void> => {
        const response = await roleService.getAppRole();
        if (response.code === 200) {
            context.commit('setAppRoles', convertRole(response.data));
        }
    },
    logout: (context: any): void => {
        context.commit('setToken', null);
        context.commit('setUser', null);
        router.push({ name: 'Login' });
    }
};
