
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { calculateWindowSize } from '@/utils/helpers';

export default defineComponent({
    name: 'App',
    setup() {
        const store = useStore();
        const windowSize = ref(calculateWindowSize(window.innerWidth));

        function resize() {
            windowSize.value = calculateWindowSize(window.innerWidth);
        }

        onMounted(() => {
            window.addEventListener('resize', resize);
        });
        onUnmounted(() => {
            window.removeEventListener('resize', resize);
        });

        watch(windowSize, value => store.dispatch('ui/setWindowSize', value));

        return {};
    }
});
