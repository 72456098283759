
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useEventBus } from '@/plugins/event-bus';
import { useVModal } from '@/plugins/modal/v-modal';

export default defineComponent({
    name: 'app-dialog',
    setup() {
        const DIALOG_NAME = 'app-dialog';
        const eventBus = useEventBus();
        const { open: openModal, close: closeModal } = useVModal();

        const model = reactive({
            title: '' as string,
            message: '' as string,
            onConfirm: (() => {}) as (value: boolean) => void | Promise<void>
        });

        onBeforeMount(() => {
            eventBus.on('dialogShow', (params: any) => {
                model.title = params.title;
                model.message = params.message;
                model.onConfirm = params.onConfirm;
                openModal({ name: DIALOG_NAME });
            });
        });

        const close = () => {
            model.title = '';
            model.message = '';
            model.onConfirm = () => {};
            closeModal({ name: DIALOG_NAME });
        };

        const onConfirm = async (value: boolean) => {
            try {
                // prevent dialog popup doesn't close if callback throw exception
                const callbackFunc = model.onConfirm ?? null;
                // reset data
                close();

                if (callbackFunc != null && typeof callbackFunc === 'function') {
                    await callbackFunc(value);
                }
            } catch (err: any) {
                throw new Error(err?.message || 'Something is wrong!');
            }
        };

        return { model, DIALOG_NAME, close, onConfirm };
    }
});
