import { defaultStorePagingProps } from '@/store/mixins';

const activityModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { accountId: null as number, feature: null as string, action: 0 }
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: { accountId: number; feature: string; action: number }): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: { accountId: number; feature: string; action: number }): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default activityModule;
