const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const CreditTicketList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');
const CreditTicketAdd = () => import(/* webpackChunkName: "exoty" */ './view/add.vue');
const CreditTicketEdit = () => import(/* webpackChunkName: "exoty" */ './view/edit.vue');

export default [
    {
        path: '/app/credit/ticket',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'CreditTicketList',
                component: CreditTicketList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'CreditTicketAdd',
                component: CreditTicketAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'CreditTicketEdit',
                component: CreditTicketEdit,
                meta: { requiresAuth: true }
            }
        ]
    }
];
