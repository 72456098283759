const maintenanceModule = {
    namespaced: true,
    state: {
        isMaintained: false
    },
    mutations: {
        setMaintenance: (state: any, isMaintained: boolean): void => {
            state.isMaintained = isMaintained;
        }
    },
    actions: {
        setMaintenance: (context: any, isMaintained: boolean): void => {
            context.commit('setMaintenance', isMaintained);
        }
    },
    getters: {
        isMaintained: (state: any): any => state.isMaintained
    }
};

export default maintenanceModule;
