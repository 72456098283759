import { defaultStorePagingProps } from '@/store/mixins';

const translationModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: {
            locale: null as any,
            filterType: 0,
            criteria: null as any
        }
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: any): void => {
            state.filter.locale = filter.locale ?? null;
            state.filter.filterType = filter.filterType ?? 0;
            state.filter.criteria = filter.criteria ?? null;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: any): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default translationModule;
