import { IUser } from './user';

export enum Role {
    ADMIN = 'ROLE_ADMIN',
    MANAGER = 'ROLE_MANAGER',
    SUPER_USER = 'ROLE_SUPER_USER'
}

export interface IAuthState {
    token: string;
    user: IUser;
    roles: Array<Role>;
    appRoles: Array<Role>;
}

export interface IAuthModule {
    namespaced: boolean;
    state: IAuthState;
    mutations: any;
    actions: any;
    getters: any;
}
