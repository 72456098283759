const promotionCodeModule = {
    namespaced: true,
    state: {
        feature: null as number,
        page: 1
    },
    mutations: {
        setPage: (state: any, page: number): void => {
            state.page = page;
        },
        setFeature: (state: any, feature: number): void => {
            state.feature = feature;
        }
    },
    actions: {
        changePage: (context: any, page: number): void => {
            context.commit('setPage', page);
        },
        changeFeature: (context: any, feature: number): void => {
            context.commit('setFeature', feature);
        }
    },
    getters: {
        page: (state: any): number => state.page,
        feature: (state: any): number => state.feature
    }
};

export default promotionCodeModule;
