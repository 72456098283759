import { defaultStorePagingProps } from '@/store/mixins';

const itemModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { forAppId: '', name: '' }
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: any): void => {
            state.filter.forAppId = filter.forAppId;
            state.filter.name = filter.name;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: any): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default itemModule;
