const rfmModule = {
    namespaced: true,
    state: {
        groupId: null as number
    },
    mutations: {
        setGroupId: (state: any, groupId: number): void => {
            state.groupId = groupId;
        }
    },
    actions: {
        changeGroupId: (context: any, groupId: number): void => {
            context.commit('setGroupId', groupId);
        }
    },
    getters: {
        groupId: (state: any): number => state.groupId
    }
};

export default rfmModule;
