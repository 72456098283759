import { IAuthModule } from '@/interfaces/state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const authModule: IAuthModule = {
    namespaced: true,
    state: {
        token: null,
        user: null,
        roles: [],
        appRoles: []
    },
    mutations,
    actions,
    getters
};

export default authModule;
