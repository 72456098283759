import store from '@/store';
import axios from 'axios';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

const authData = computed(() => {
    return {
        token: store.getters['auth/token'],
        appId: store.getters['application/appId']
    };
});

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-type': 'application/x-www-form-urlencoded'
    },
    params: {}
});

instance.interceptors.request.use(
    request => {
        if (request.method.toUpperCase() != Method.GET) {
            store.dispatch('ui/loading', true);
        }

        return request;
    },
    error => {
        if (error.config.method.toUpperCase() != Method.GET) {
            store.dispatch('ui/loading', false);
        }

        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        if (response.config.method.toUpperCase() != Method.GET) {
            store.dispatch('ui/loading', false);
        }

        // SessionExpired or InvalidToken
        if (response.data.code === 600 || response.data.code === 623) {
            store.dispatch('auth/logout');
        }

        if (response.data.code === 200 || response.data.code === 404) {
            return response.data;
        }

        return Promise.reject(response.data);
    },
    error => {
        if (error.config.method.toUpperCase() != Method.GET) {
            store.dispatch('ui/loading', false);
        }

        Promise.reject(error);
    }
);

const getError = (error: any) => {
    const message = error.message || error.data || 'Failed';
    toast.error(message, { timeout: 2000 });
    return new Error(message);
};

const _request = (method: any = null, url: any = null, params: any = null, files: any = null) => {
    if (!params) {
        params = {};
    }

    params['exoty_token'] = authData.value.token;
    if (!Object.prototype.hasOwnProperty.call(params, 'appId') || !params['appId']) {
        params['appId'] = authData.value.appId;
    }

    for (const name in params) {
        if (params[name] === null || params[name] === undefined) {
            delete params[name];
        }
    }

    // if (method === 'URL') {
    //     window.open(
    //         this.get('ajax').get('host') + '/' + url + '?' + $.param(params),
    //         '_blank'
    //     );
    //     return;
    // }

    if (method !== Method.POST && method !== Method.GET) {
        params._method = method;
        method = Method.POST;
    }

    if (method !== Method.GET) {
        const formData = new FormData();
        for (const p in params) {
            formData.append(p, params[p]);
        }

        if (files) {
            for (const file in files) {
                if (files[file] !== null || files[file] !== undefined) {
                    formData.append(file, files[file]);
                }
            }
        }

        params = formData;
    }

    let config;
    if (method !== Method.GET) {
        config = {
            url: url,
            method: method,
            data: params
        };
    } else {
        config = {
            url: url,
            method: method,
            params: params
        };
    }

    return instance
        .request(config)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            throw getError(error);
        });
};

const connection = {
    doGet: function (url: any, params: any = null) {
        return _request(Method.GET, url, params);
    },

    doPut: function (url: any, params: any = null, files: any = null) {
        return _request(Method.PUT, url, params, files);
    },

    doPost: function (url: any, params: any = null, files: any = null) {
        return _request(Method.POST, url, params, files);
    },

    doDelete: function (url: any, params: any = null) {
        return _request(Method.DELETE, url, params);
    }
};

export default connection;
