const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const FrequencyAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/frequency/view/add.vue');
const FrequencyEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/frequency/view/edit.vue');
const FrequencyList = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/frequency/view/index.vue');
const MonetaryAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/monetary/view/add.vue');
const MonetaryEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/monetary/view/edit.vue');
const MonetaryList = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/monetary/view/index.vue');
const PersonaAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/persona/view/add.vue');
const PersonaEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/persona/view/edit.vue');
const PersonaList = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/persona/view/index.vue');
const RecencyAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/recency/view/add.vue');
const RecencyEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/recency/view/edit.vue');
const RecencyList = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/recency/view/index.vue');
const Segmentation = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/segmentation/edit.vue');
const RfmGroupAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/group/view/add.vue');
const RfmGroupEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/group/view/edit.vue');
const RfmGroupList = () => import(/* webpackChunkName: "exoty" */ '@/modules/rfm/group/view/index.vue');

export default [
    {
        path: '/app/rfm/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'segmentation',
                name: 'Segmentation',
                component: Segmentation,
                meta: { requiresAuth: true }
            },
            {
                path: 'recency/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'RecencyList',
                        component: RecencyList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'RecencyAdd',
                        component: RecencyAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'RecencyEdit',
                        component: RecencyEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'frequency/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'FrequencyList',
                        component: FrequencyList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'FrequencyAdd',
                        component: FrequencyAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'FrequencyEdit',
                        component: FrequencyEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'monetary/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'MonetaryList',
                        component: MonetaryList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'MonetaryAdd',
                        component: MonetaryAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'MonetaryEdit',
                        component: MonetaryEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'persona/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PersonaList',
                        component: PersonaList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PersonaAdd',
                        component: PersonaAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'PersonaEdit',
                        component: PersonaEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'group/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'RfmGroupList',
                        component: RfmGroupList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'RfmGroupAdd',
                        component: RfmGroupAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'RfmGroupEdit',
                        component: RfmGroupEdit,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];
