import { SCREEN_SIZE_TYPE } from '@/utils/themes';

export default {
    darkModeSelected: (state: any): boolean => state.darkMode,
    menuSidebarCollapsed: (state: any): boolean => state.menuSidebarCollapsed,
    controlSidebarCollapsed: (state: any): boolean => state.controlSidebarCollapsed,
    screenSize: (state: any): SCREEN_SIZE_TYPE => state.screenSize,
    navbarVariant: (state: any): string => state.navbarVariant,
    sidebarSkin: (state: any): string => state.sidebarSkin,
    isLoading: (state: any): boolean => state.isLoading,
    refCount: (state: any): number => state.refCount
};
