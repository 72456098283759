const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const Facebook = () => import(/* webpackChunkName: "exoty" */ './view/edit.vue');

export default [
    {
        path: '/app/facebook',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'Facebook',
                component: Facebook,
                meta: { requiresAuth: true }
            }
        ]
    }
];
