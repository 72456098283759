import { App, inject, Plugin } from 'vue';
import AppDialog from '@/plugins/dialog/v-dialog.vue';
import { useEventBus } from '@/plugins/event-bus';

export default {
    install: (app: App) => {
        app.component('AppDialog', AppDialog);

        const eventBus = useEventBus();

        const actions: VDialogAction = {
            show(params) {
                eventBus.emit('dialogShow', params);
            },
            confirm(params) {
                this.show({ title: 'Confirmation', ...params });
            }
        };

        /* It is common to use both `app.config.globalProperties` and `provide` in the same plugin */
        app.config.globalProperties.$vDialog = actions;
        app.provide('vDialog', actions);
    }
} as Plugin;

export function useVDialog() {
    const vDialog = inject<VDialogAction>('vDialog');
    if (!vDialog) {
        throw new Error('No vDialog provided!');
    }

    return vDialog;
}

type VDialogAction = {
    show: (params: { title: string; message: string; onConfirm: (value: boolean) => void | Promise<void> }) => void;
    confirm: (params: { message: string; onConfirm: (value: boolean) => void | Promise<void> }) => void;
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $vDialog: VDialogAction;
    }
}
