import router from '@/router';

const registerModule = (name: string, module: any) => {
    if (module.router) {
        module.addRoutes(router);
    }
};

export const registerModules = (modules: any) => {
    Object.keys(modules).forEach(moduleKey => registerModule(moduleKey, modules[moduleKey]));
};
