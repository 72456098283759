const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const BonusBoost = () => import(/* webpackChunkName: "exoty" */ './boost/view/edit.vue');
const BonusLoginAdd = () => import(/* webpackChunkName: "exoty" */ './login/view/add.vue');
const BonusLoginAds = () => import(/* webpackChunkName: "exoty" */ './login/view/ads.vue');
const BonusLoginEdit = () => import(/* webpackChunkName: "exoty" */ './login/view/edit.vue');
const BonusLoginList = () => import(/* webpackChunkName: "exoty" */ './login/view/index.vue');

export default [
    {
        path: '/app/bonus',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'login',
                name: 'BonusLogin',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'BonusLoginList',
                        component: BonusLoginList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'BonusLoginAdd',
                        component: BonusLoginAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'BonusLoginEdit',
                        component: BonusLoginEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/ads',
                        name: 'BonusLoginAds',
                        component: BonusLoginAds,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'boost',
                name: 'BonusBoost',
                component: BonusBoost,
                meta: { requiresAuth: true }
            }
        ]
    }
];
