const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const GoogleLinkedApp = () => import(/* webpackChunkName: "exoty" */ '@/modules/google/view/linkedapp.vue');
const GooglePayment = () => import(/* webpackChunkName: "exoty" */ '@/modules/google/view/payment.vue');

export default [
    {
        path: '/app/google',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'payment',
                name: 'GooglePayment',
                component: GooglePayment,
                meta: { requiresAuth: true }
            },
            {
                path: 'linkedapp',
                name: 'GoogleLinkedApp',
                component: GoogleLinkedApp,
                meta: { requiresAuth: true }
            }
        ]
    }
];
