import { calculateWindowSize } from '@/utils/helpers';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const uiModule = {
    namespaced: true,
    state: {
        darkMode: process.env.VUE_APP_DARK_MODE.toLowerCase() == 'true',
        navbarVariant: process.env.VUE_APP_NAVBAR_VARIANT,
        sidebarSkin: process.env.VUE_APP_SIDEBAR_SKIN,
        menuSidebarCollapsed: false,
        controlSidebarCollapsed: true,
        screenSize: calculateWindowSize(window.innerWidth),
        refCount: 0,
        isLoading: false
    },
    mutations,
    actions,
    getters
};

export default uiModule;
