const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const Maintenance = () => import(/* webpackChunkName: "exoty" */ './maintenance.vue');

export default [
    {
        path: '/system/maintenance',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'Maintenance',
                component: Maintenance,
                meta: { requiresAuth: true }
            }
        ]
    }
];
