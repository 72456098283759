import { defaultStorePagingProps } from '@/store/mixins';

const promotionItemModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { type: 0 as number, status: -1 as number, rfmPersonaId: null as number }
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: { type: number; status: number; rfmPersonaId: number }): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: { type: number; status: number; rfmPersonaId: number }): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default promotionItemModule;
