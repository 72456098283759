import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-625df731"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-modal"]
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.model.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-modal": _ctx.name,
        class: "modal fade show custom-style-modal",
        "aria-modal": "true"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {
                payload: _ctx.model.payload
              }, undefined, true)
            ])
          ])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}