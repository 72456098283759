import { App, Plugin } from 'vue';
import mitt from 'mitt';

const eventBus = mitt();

export default {
    install: (app: App) => {
        app.config.globalProperties.$eventBus = eventBus;
        app.provide('eventBus', eventBus);
    }
} as Plugin;

export function useEventBus() {
    if (!eventBus) {
        throw new Error('No eventBus provided!');
    }

    return eventBus;
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $eventBus: typeof eventBus;
    }
}
