const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const IAPAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/iap/view/add.vue');
const IAPClone = () => import(/* webpackChunkName: "exoty" */ '@/modules/iap/view/clone.vue');
const IAPEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/iap/view/edit.vue');
const IAPList = () => import(/* webpackChunkName: "exoty" */ '@/modules/iap/view/index.vue');

export default [
    {
        path: '/app/iap',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'IAPList',
                component: IAPList,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'IAPEdit',
                component: IAPEdit,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'IAPAdd',
                component: IAPAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/clone',
                name: 'IAPClone',
                component: IAPClone,
                meta: { requiresAuth: true }
            }
        ]
    }
];
