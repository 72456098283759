const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const SystemItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/add.vue');
const SystemItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/edit.vue');
const SystemItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/index.vue');
const SystemItemLink = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/link.vue');
const SystemItemTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/system/view/translate.vue');
const AppItemSettingAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/add.vue');
const AppItemSettingEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/edit.vue');
const AppItemSetting = () => import(/* webpackChunkName: "exoty" */ '@/modules/item/app/setting/view/index.vue');

export default [
    {
        path: '/system/item',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'SystemItemList',
                component: SystemItemList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'SystemItemAdd',
                component: SystemItemAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/edit',
                name: 'SystemItemEdit',
                component: SystemItemEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/link',
                name: 'SystemItemLink',
                component: SystemItemLink,
                meta: { requiresAuth: true }
            },
            {
                path: ':itemId/translate',
                name: 'SystemItemTranslate',
                component: SystemItemTranslate,
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/app/item/setting',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'AppItemSettingList',
                component: AppItemSetting,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'AppItemSettingAdd',
                component: AppItemSettingAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                name: 'AppItemSettingEdit',
                component: AppItemSettingEdit,
                meta: { requiresAuth: true }
            }
        ]
    }
];
