import { defaultStorePagingProps } from '@/store/mixins';

const arenaRankModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { accountId: null as number }
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: any): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: any): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default arenaRankModule;
