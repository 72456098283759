import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import accountModule from './account';
import accountActivityModule from './account/activity';
import accountTransactionModule from './account/transaction';
import activityModule from './activity';
import applicationModule from './applications';
import arenaItemModule from './arena/item';
import arenaRankModule from './arena/item/rank';
import authModule from './auth';
import avatarBackgroundModule from './avatar/background';
import avatarBundleModule from './avatar/bundle';
import avatarCategoryModule from './avatar/category';
import avatarDefaultModule from './avatar/default';
import avatarItemModule from './avatar/item';
import avatarImageModule from './avatar/item/image';
import boosterModule from './booster';
import duplicateCycleModule from './duplicate/cycle';
import duplicateRankModule from './duplicate/cycle/item/rank';
import duplicateItemModule from './duplicate/item';
import itemModule from './item';
import localeModule from './locale';
import maintenanceModule from './maintenance';
import newsModule from './news';
import progressModule from './progress';
import promotionCodeModule from './promotion/code';
import promotionItemModule from './promotion/item';
import promotionPrototypeModule from './promotion/prototype';
import rfmModule from './rfm';
import roleModule from './role';
import ticketModule from './scratch/ticket';
import subscriptionItemModule from './subscription/item';
import surpriseCategoryModule from './surprise/category';
import surpriseItemModule from './surprise/category/item';
import tournamentCycleModule from './tournament/cycle';
import tournamentLeaderboardModule from './tournament/leaderboard';
import transactionModule from './transaction';
import translationModule from './translation';
import uiModule from './ui';
import versionModule from './version';

export default createStore({
    modules: {
        auth: authModule,
        ui: uiModule,
        ticket: ticketModule,
        role: roleModule,
        application: applicationModule,
        subscriptionItem: subscriptionItemModule,
        promotionItem: promotionItemModule,
        promotionCode: promotionCodeModule,
        promotionPrototype: promotionPrototypeModule,
        version: versionModule,
        locale: localeModule,
        translation: translationModule,
        transaction: transactionModule,
        news: newsModule,
        account: accountModule,
        accountActivity: accountActivityModule,
        accountTransaction: accountTransactionModule,
        surpriseCategory: surpriseCategoryModule,
        surpriseItem: surpriseItemModule,
        progress: progressModule,
        duplicateItem: duplicateItemModule,
        duplicateCycle: duplicateCycleModule,
        duplicateRank: duplicateRankModule,
        tournamentLeaderboard: tournamentLeaderboardModule,
        tournamentCycle: tournamentCycleModule,
        item: itemModule,
        arenaItem: arenaItemModule,
        arenaRank: arenaRankModule,
        avatarDefault: avatarDefaultModule,
        avatarBundle: avatarBundleModule,
        avatarCategory: avatarCategoryModule,
        avatarItem: avatarItemModule,
        avatarImage: avatarImageModule,
        avatarBackground: avatarBackgroundModule,
        maintenance: maintenanceModule,
        rfm: rfmModule,
        activity: activityModule,
        booster: boosterModule
    },
    plugins: [
        createPersistedState({
            key: 'vuex',
            reducer(state: any) {
                return !state.auth.token?.length ? {} : state;
            }
        })
    ]
});
