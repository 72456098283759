const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const ChatSentenceAdd = () => import(/* webpackChunkName: "exoty" */ './sentence/view/add.vue');
const ChatSentenceEdit = () => import(/* webpackChunkName: "exoty" */ './sentence/view/edit.vue');
const ChatSentenceList = () => import(/* webpackChunkName: "exoty" */ './sentence/view/index.vue');
const ChatSentenceTranslate = () => import(/* webpackChunkName: "exoty" */ './sentence/view/translate.vue');
const ChatSetting = () => import(/* webpackChunkName: "exoty" */ './setting/view/edit.vue');

export default [
    {
        path: '/app/chat',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'sentence',
                name: 'ChatSentenceList',
                component: ChatSentenceList,
                meta: { requiresAuth: true }
            },
            {
                path: 'sentence/add',
                name: 'ChatSentenceAdd',
                component: ChatSentenceAdd,
                meta: { requiresAuth: true }
            },
            {
                path: 'sentence/:id/edit',
                name: 'ChatSentenceEdit',
                component: ChatSentenceEdit,
                meta: { requiresAuth: true }
            },
            {
                path: 'sentence/:id/translate',
                name: 'ChatSentenceTranslate',
                component: ChatSentenceTranslate,
                meta: { requiresAuth: true }
            },
            {
                path: 'setting',
                name: 'ChatSetting',
                component: ChatSetting,
                meta: { requiresAuth: true }
            }
        ]
    }
];
