
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useEventBus } from '@/plugins/event-bus';

export default defineComponent({
    name: 'app-modal',
    props: {
        name: { type: String, required: true }
    },
    setup(props) {
        const eventBus = useEventBus();

        const model = reactive({
            payload: null,
            visible: false
        });

        onBeforeMount(() => {
            eventBus.on('open', (params: any) => {
                if (props.name === params.name) {
                    model.visible = true;
                    model.payload = params.payload;
                }
            });

            eventBus.on('close', (params: any) => {
                if (props.name === params.name) {
                    model.visible = false;
                    model.payload = null;
                }
            });
        });

        return { model };
    }
});
