const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const TranslationAdd = () => import(/* webpackChunkName: "exoty" */ './view/add.vue');
const TranslationExport = () => import(/* webpackChunkName: "exoty" */ './view/export.vue');
const TranslationList = () => import(/* webpackChunkName: "exoty" */ './view/index.vue');
const TranslationTranslate = () => import(/* webpackChunkName: "exoty" */ './view/translate.vue');

export default [
    {
        path: '/app/translation',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'export',
                name: 'TranslationExport',
                component: TranslationExport,
                meta: { requiresAuth: true }
            },
            {
                path: 'item',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'TranslationList',
                        component: TranslationList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'TranslationAdd',
                        component: TranslationAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'TranslationEdit',
                        component: TranslationAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'TranslationTranslate',
                        component: TranslationTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];
