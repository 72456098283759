const Main = () => import(/* webpackChunkName: "exoty" */ './main.vue');

export default [
    {
        path: '/',
        name: 'Home',
        component: Main,
        meta: { requiresAuth: true }
    }
];
